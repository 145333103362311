import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MyContext } from '../../../App';
import Shimmer from '../../../utils/Shimmer';

const Header = () => {

   const { user, currentTab } = useContext(MyContext);

   return (
      <header className="header">
         <div className="header-top js-navbar">
            <div className="container">
               <div className="header-top__inner js-header-top">
                  <a href="/" className="logo">
                     <img src="/images/logo.svg" alt="kukareku.io" />
                  </a>
                  <div className="profile js-profile">
                     <div className="profile__item profile__item_address">
                        <span className="profile__address">{user ? user.wallet : <Shimmer data={{ w: '100%', h: 20 }} />}</span>
                        <span className="profile__subtitle">Your wallet</span>
                     </div>
                     <div className="profile__item profile__item_balance">
                        <span className="profile__amount">{user ? user.dep_amount.toFixed(2) + " TRX" : <Shimmer data={{ w: '100%', h: 20 }} />}</span>
                        <span className="profile__subtitle">Total deposits</span>
                     </div>
                     <div className="profile__item profile__item_total">
                        <span className="profile__amount">{user ? user.pay_amount.toFixed(2) + " TRX" : <Shimmer data={{ w: '100%', h: 20 }} />}</span>
                        <span className="profile__subtitle">Total payouts</span>
                     </div>
                  </div>
                  <button className="button button_gradient header-top__button js-auth" id="logout-button">
                     Log out                        </button>
                  <div className="burger-btn js-burger-btn">
                     <span className="burger-btn__line"></span>
                  </div>
               </div>
            </div>
         </div>
         <div className="header-bottom">
            <div className="container">
               <div className="nav-menu js-nav-menu">
                  <ul className="nav-menu__list js-anchors">
                     <li><a href="/">Home</a></li>
                     <li className={`${currentTab === 'Cabinet' ? 'active' : ''}`}><Link to="/cabinet">My profile</Link></li>
                     <li className={`${currentTab === 'Create' ? 'active' : ''}`}><Link to="/cabinet/createDeposit">Make a deposit</Link></li>
                     <li className={`${currentTab === 'Deposits' ? 'active' : ''}`}><Link to="/cabinet/deposits">My deposits</Link></li>
                     <li className={`${currentTab === 'Payouts' ? 'active' : ''}`}><Link to="/cabinet/payouts">My payouts</Link></li>
                     <li className={`${currentTab === 'Partners' ? 'active' : ''}`}><Link to="/cabinet/partners">My partners</Link></li>
                  </ul>
               </div>
            </div>
         </div>
      </header>
   );
};

export default Header;