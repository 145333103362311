import React, { useContext, useEffect } from 'react';
import { MyContext } from '../../App';
import { CheckIfWalletOrLogout, Time, convertDate } from '../../utils/Utils';
import axios from 'axios';
import { Configs } from '../../config';
import { IDepositsResponse } from '../../interfaces/ServerResponse';
import Shimmer from '../../utils/Shimmer';

const Deposits = () => {

   const { deposits, setDeposits, depositsLast, setDepositsLast, currentTab, setCurrentTab, user, UpdateUserData } = useContext(MyContext);

   useEffect(() => {
      if (currentTab !== "Deposits") setCurrentTab("Deposits");
      const wallet = CheckIfWalletOrLogout();
      if (!wallet) return;
      if (!user) UpdateUserData();
      let ourReq = axios.CancelToken.source();
      if (depositsLast < Time()) {
         setDeposits(null);
         (async () => {
            try {
               const data = (await axios.get(`${Configs.API_URL}/deps`, { headers: { jwt: wallet }, cancelToken: ourReq.token })).data as IDepositsResponse;
               if (!data.data) return;
               setDeposits(data.data.deposits);
               setDepositsLast(Time() + 5);
            }
            catch (e) {
               return;
            }
         })();
      }
      return () => ourReq.cancel();
   }, [depositsLast, setDeposits, setDepositsLast, currentTab, setCurrentTab, UpdateUserData, user]);

   return (
      <div className="profile-stat" style={{ marginTop: `30px` }}>
         <div className="container">
            <h2 style={{ textAlign: `center` }}>Your deposits</h2>
            <div className="profile-stat__inner">
               <div className="profile-stat-table-wrap">
                  <table className="profile-stat-table">
                     <tbody>
                        <tr>
                           <th>Open date</th>
                           <th>Close date</th>
                           <th>Amount</th>
                           <th>Accrued</th>
                           <th>Status</th>
                        </tr>
                        {
                           Array.isArray(deposits) ? (
                              deposits.length ? (
                                 deposits.map((deposit, i) => (
                                    <tr key={i}>
                                       <td>{convertDate(deposit.started)}</td>
                                       <td>{convertDate(deposit.started + 86400)}</td>
                                       <td>{deposit.amount.toFixed(2)} TRX</td>
                                       <td>{(deposit.amount * 1.5).toFixed(2)}</td>
                                       <td>{(deposit.started + 86400) > Time() ? `Active` : `Closed`}</td>
                                    </tr>
                                 ))
                              ) : (
                                 <tr>
                                    <td colSpan={5} style={{ textAlign: `center` }}>You have no deposits</td>
                                 </tr>
                              )
                           ) : (
                              <>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                              </>
                           )
                        }
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Deposits;