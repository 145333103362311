import React, { useContext, useEffect } from 'react';
import { MyContext } from '../../App';
import { CheckIfWalletOrLogout, Time, convertDate } from '../../utils/Utils';
import axios from 'axios';
import { Configs } from '../../config';
import { IPaymentsResponse } from '../../interfaces/ServerResponse';
import Shimmer from '../../utils/Shimmer';

const Payments = () => {

   const { payments, paymentsLast, setPayments, setPaymentsLast, currentTab, setCurrentTab, user, UpdateUserData } = useContext(MyContext);

   useEffect(() => {
      if (currentTab !== "Payouts") setCurrentTab("Payouts")
      const wallet = CheckIfWalletOrLogout();
      if (!wallet) return;
      if (!user) UpdateUserData();
      let ourReq = axios.CancelToken.source();
      if (paymentsLast < Time()) {
         setPayments(null);
         (async () => {
            try {
               const data = (await axios.get(`${Configs.API_URL}/pays`, { headers: { jwt: wallet }, cancelToken: ourReq.token })).data as IPaymentsResponse;
               if (!data.data) return;
               setPayments(data.data.payouts);
               setPaymentsLast(Time() + 5);
            }
            catch (e) {
               return;
            }
         })();
      }
      return () => ourReq.cancel();
   }, [paymentsLast, setPayments, setPaymentsLast, setCurrentTab, currentTab, user, UpdateUserData]);

   return (
      <div className="profile-stat" style={{ marginTop: `30px` }}>
         <div className="container">
            <h2 style={{ textAlign: `center` }}>Your payments</h2>
            <div className="profile-stat__inner">
               <div className="profile-stat-table-wrap">
                  <table className="profile-stat-table">
                     <tbody>
                        <tr>
                           <th>Date</th>
                           <th>Wallet</th>
                           <th>Amount</th>
                           <th>Batch</th>
                        </tr>
                        {
                           Array.isArray(payments) ? (
                              payments.length ? (
                                 payments.map((payment, i) => (
                                    <tr key={i}>
                                       <td>{convertDate(payment.time)}</td>
                                       <td>{payment.amount.toFixed(2)} TRX</td>
                                       <td>{(payment.amount * 1.5).toFixed(2)}</td>
                                       <td>{(payment.hash ? payment.hash : `Pending`)}</td>
                                    </tr>
                                 ))
                              ) : (
                                 <tr>
                                    <td colSpan={5} style={{ textAlign: `center` }}>You have no payments</td>
                                 </tr>
                              )
                           ) : (
                              <>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                              </>
                           )
                        }
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Payments;