import React, { createContext, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import RootLayout from './components/dash/common/RootLayout';
import Cabinet from './components/dash/Cabinet';
import Deposits from './components/dash/Deposits';
import Payouts from './components/dash/Payouts';
import CreateDeposit from './components/dash/CreateDeposit';
import { Toaster } from 'react-hot-toast';
import { IDeposit, IPartner, IPayout, IUser } from './interfaces/interfaces';
import { Time, ValidateWallet } from './utils/Utils';
import axios from 'axios';
import { Configs } from './config';
import { ILoginResponse } from './interfaces/ServerResponse';
import Partners from './components/dash/Partners';


export interface IMyConext {
   currentTab: 'Home' | 'Cabinet' | 'Create' | 'Deposits' | 'Payouts' | 'Partners';
   setCurrentTab: React.Dispatch<React.SetStateAction<'Home' | 'Cabinet' | 'Create' | 'Deposits' | 'Payouts' | 'Partners'>>;
   UpdateUserData: () => void,

   user: IUser | null;
   setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
   userLast: number;
   setUserLast: React.Dispatch<React.SetStateAction<number>>;

   deposits: IDeposit[] | null;
   depositsLast: number;
   setDeposits: React.Dispatch<React.SetStateAction<IDeposit[] | null>>;
   setDepositsLast: React.Dispatch<React.SetStateAction<number>>;

   payments: IPayout[] | null;
   paymentsLast: number;
   setPayments: React.Dispatch<React.SetStateAction<IPayout[] | null>>;
   setPaymentsLast: React.Dispatch<React.SetStateAction<number>>;

   partners: IPartner | null;
   partnersLast: number;
   setPartners: React.Dispatch<React.SetStateAction<IPartner | null>>;
   setPartnersLast: React.Dispatch<React.SetStateAction<number>>;

}

export const MyContext = createContext<IMyConext>({
   currentTab: 'Home',
   setCurrentTab: () => { },
   UpdateUserData: () => { },

   user: null,
   setUser: () => { },
   userLast: 0,
   setUserLast: () => { },
   deposits: null,
   depositsLast: 0,
   setDeposits: () => { },
   setDepositsLast: () => { },
   payments: null,
   paymentsLast: 0,
   setPayments: () => { },
   setPaymentsLast: () => { },
   partners: null,
   partnersLast: 0,
   setPartners: () => { },
   setPartnersLast: () => { },
})

function App() {
   const [currentTab, setCurrentTab] = useState<'Home' | 'Cabinet' | 'Create' | 'Deposits' | 'Payouts' | 'Partners'>('Home');

   const [user, setUser] = useState<IUser | null>(null);
   const [userLast, setUserLast] = useState<number>(0);
   const [deposits, setDeposits] = useState<IDeposit[] | null>(null);
   const [depositsLast, setDepositsLast] = useState<number>(0);
   const [payments, setPayments] = useState<IPayout[] | null>(null);
   const [paymentsLast, setPaymentsLast] = useState<number>(0);
   const [partners, setPartners] = useState<IPartner | null>(null);
   const [partnersLast, setPartnersLast] = useState<number>(0);

   const UpdateUserData = () => {
      setUser(null);
      (async () => {
         try {
            const wallet = localStorage.getItem('wallet');
            if (!wallet || !ValidateWallet(wallet)) return;
            const data = (await axios.post(`${Configs.API_URL}/auth`, { wallet })).data as ILoginResponse;
            if (!data.data.wallet) return;
            setUser(data.data);
            setUserLast(Time() + 5);
         }
         catch (e) {
            return;
         }
      })();
   }

   return (
      <>
         <Toaster position='top-center' toastOptions={{ duration: 2000 }} />
         <MyContext.Provider value={{ currentTab, setCurrentTab, UpdateUserData, user, setUser, userLast, setUserLast, deposits, depositsLast, setDeposits, setDepositsLast, payments, paymentsLast, setPayments, setPaymentsLast, partners, partnersLast, setPartners, setPartnersLast }}>
            <Routes>
               <Route path="/" element={<Home />} />
               <Route path="/cabinet" element={<RootLayout />}>
                  <Route index element={<Cabinet />} />
                  <Route path="deposits" element={<Deposits />} />
                  <Route path="payouts" element={<Payouts />} />
                  <Route path="partners" element={<Partners />} />
                  <Route path="createDeposit" element={<CreateDeposit />} />
               </Route>
            </Routes>
         </MyContext.Provider>
      </>
   );
}

export default App;
