import { useContext, useEffect, useState } from "react";
import { MyContext } from "../../App";
import axios from "axios";
import { Configs } from "../../config";
import { ICreateResponse } from "../../interfaces/ServerResponse";
import { ICreate } from "../../interfaces/interfaces";
import Shimmer from "../../utils/Shimmer";
import { AccountToCurrencySymbol, CopyToClipboard } from "../../utils/Utils";

export type ECoins = 'BTC' | 'LTC' | 'DOGE' | 'ETH' | 'TRX' | 'BNB' | 'USDT_TRC20' | 'USDT_ERC20' | 'USDT_BEP20' | 'USDC_TRC20' | 'USDC_ERC20' | 'USDC_BEP20';
const CreateDeposit = () => {

   const coins: ECoins[] = ['BTC', 'LTC', 'DOGE', 'ETH', 'TRX', 'BNB', 'USDT_TRC20', 'USDT_ERC20', 'USDT_BEP20', 'USDC_TRC20', 'USDC_ERC20', 'USDC_BEP20'];

   const { user, setCurrentTab, currentTab, UpdateUserData } = useContext(MyContext);
   useEffect(() => { (currentTab !== "Create") && setCurrentTab("Create") }, [currentTab, setCurrentTab]);
   const [currentCoin, setCurrentCoin] = useState<ECoins>("TRX");
   const [canChange, setCanChange] = useState<boolean>(true);

   const [walletData, setWalletData] = useState<ICreate | null | false>(null);
   useEffect(() => console.log('walletData', walletData), [walletData]);


   useEffect(() => {
      if (!user) UpdateUserData();
   }, [user, UpdateUserData]);

   useEffect(() => {
      if (!user) return;
      let ourReq = axios.CancelToken.source();
      (async () => {
         try {
            setWalletData(false);
            setCanChange(false);
            const data = (await axios.get(`${Configs.API_URL}/create?coin=${currentCoin}`, { headers: { jwt: user.wallet }, cancelToken: ourReq.token })).data as ICreateResponse;
            if (!data) return setWalletData(null);
            setWalletData(data.data);
         }
         catch (e) {
            setWalletData(null);
         }
         finally {
            setCanChange(true);
         }
      })();
      return () => ourReq.cancel();
   }, [user, currentCoin]);


   return (
      <div className="make-deposit">
         <div className="container">
            <div className="deposit">
               <div className="deposit__title">
                  Make a deposit
               </div>
               <div className="deposit__choise">
                  <div className="deposit__inner">
                     <div className="deposit__text deposit__row">
                        <div className="deposit__col">
                           <span className="deposit__col-title">
                              Minimum amount for deposit
                           </span>
                           <span className="accent" id="min-amount">
                              {walletData ? `${walletData.min_amount} ${AccountToCurrencySymbol(currentCoin)}` : <Shimmer data={{ w: 100, h: 20 }} />}
                           </span>
                        </div>
                        <div className="deposit__col">
                           <span className="deposit__col-title">
                              Necessary number of confirmations
                           </span>
                           <span className="accent" id="need-confirms">
                              {walletData ? walletData.confirmations : <Shimmer data={{ w: 100, h: 20 }} />}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="deposit__address" id="crypto-form">
                  <label className="deposit__label" htmlFor="address">Address</label>
                  <div className="deposit__inner">
                     <div className="deposit__edit">
                        {
                           walletData ? <input className="deposit__input"
                              id="callback-address"
                              readOnly
                              type="text"
                              style={{ cursor: `copy` }}
                              value={walletData.address}
                              onClick={e => CopyToClipboard(e)}
                           /> : <Shimmer data={{ w: '100%', h: 37 }} />
                        }
                        <div className="deposit__qr-block">
                           <div className="deposit__qr-img">
                              {
                                 walletData ? (
                                    <img id="callback-qr" src={`https://chart.googleapis.com/chart?cht=qr&chs=120x120&chl=${walletData.address}&chld=|0`} className="qrcode-holder" alt="qr code" />
                                 ) : (
                                    <Shimmer data={{ w: 120, h: 120 }} />
                                 )
                              }
                           </div>
                           <div className="deposit__text">
                              <span className="deposit__subtitle">Waiting for your deposit</span>
                              <p>As soon as we receive the necessary confirmations, your deposit will be displayed on the page "My Deposits".</p>
                           </div>
                        </div>
                     </div>
                     <div className="deposit__text deposit__info">
                        <p>Waiting and deposit procedure does not take more than 15 minutes on average.</p>
                        <p>If the amount sent by you is less than the minimum deposit amount for the selected payment system, such payment will be considered as a donation.</p>
                        <strong style={{ display: currentCoin === 'TRX' ? 'none' : 'block' }}>Other currencies will be converted to TRX amount at the currenct marcket rate.</strong>
                     </div>
                  </div>
               </div>

               <div className={`currencies-for-dep ${canChange ? '' : 'no'}`}>
                  {coins.map((coin, i) => {
                     return (
                        <div className={`coins-item ${coin === currentCoin ? "active" : ""}`} key={i} onClick={() => canChange && setCurrentCoin(coin)}>
                           <img src={`/images/${coin}.png`} alt={coin} />
                           {coin}
                        </div>
                     )
                  })}
               </div>
            </div>
         </div>
      </div>
   );
};

export default CreateDeposit;