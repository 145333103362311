import React from 'react';

const Footer = () => {
   return (
      <footer className="footer" style={{ marginTop: 'auto' }}>
         <div className="container">
            <div className="footer__inner">
               <div className="footer__col">
                  <a href="/" className="logo footer__logo"><img src="/images/logo.svg" alt="trx5" /></a>
               </div>
               <div className="footer__col">
                  <span className="footer__title">Menu</span>
                  <nav className="footer-nav">
                     <ul className="footer-nav__list js-anchors">
                        <li><a href="/#statistics">Statistics</a></li>
                        <li><a href="/#faq">Help</a></li>
                     </ul>
                  </nav>
               </div>
               <div className="footer__col">
                  <span className="footer__title">Contacts</span>
                  <div className="contacts">
                     <a href="mailto:support@trx5.ltd" target="_blank" className="contacts__link contacts__link_mail" rel="noreferrer">support@trx5.ltd</a>
                     <a href="https://t.me/joinchat/T-r2GxOXP-SAQrwI" target="_blank" className="contacts__link contacts__link_telegram" rel="noreferrer">Telegram</a>
                  </div>
               </div>
            </div>
         </div>
         <div className="copy footer__copy">Copyright &copy; 2021 Trx5. All rights reserved.</div>
      </footer>
   );
};

export default Footer;