import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MyContext } from '../../App';
import { CopyToClipboard, Time, ValidateWallet } from '../../utils/Utils';
import Shimmer from '../../utils/Shimmer';
import axios from 'axios';
import { Configs } from '../../config';
import { ILoginResponse } from '../../interfaces/ServerResponse';

const Cabinet = () => {

   const { user, userLast, setUser, setUserLast, setCurrentTab, currentTab } = useContext(MyContext);

   useEffect(() => {
      if (currentTab !== "Cabinet") setCurrentTab("Cabinet");
      let ourReq = axios.CancelToken.source();
      if (userLast < Time()) {
         setUser(null);
         (async () => {
            try {
               const wallet = localStorage.getItem('wallet');
               if (!wallet || !ValidateWallet(wallet)) return;
               const data = (await axios.post(`${Configs.API_URL}/auth`, { wallet }, { cancelToken: ourReq.token })).data as ILoginResponse;
               if (!data.data.wallet) return;
               setUser(data.data);
               setUserLast(Time() + 5);
            }
            catch (e) {
               return;
            }
         })();
      }
      return () => ourReq.cancel();
   }, [userLast, setUser, setUserLast, setCurrentTab, currentTab]);



   return (
      <>
         <div className="profile-info">
            <div className="container">
               <div className="profile-info__inner">
                  <div className="offer">
                     <div className="offer__inner">
                        <Link to="/cabinet/createDeposit" className="button button_dark offer__button">
                           <i className="fas fa-magic"></i> Make a deposit
                        </Link>
                     </div>
                  </div>
                  <div className="cash">
                     <div className="cash__item cash-item cash-item_deposit">
                        <div className="cash-item__col">
                           <div className="cash-item__title">
                              Deposits
                           </div>
                           <span className="cash-item__amount">
                              {user ? `${user.dep_count}` : <Shimmer data={{ w: 80, h: 20 }} />}
                           </span>

                        </div>
                        <div className="cash-item__col">
                           <div className="cash-item__title">
                              Total deposits
                           </div>
                           <span className="cash-item__amount">
                              {user ? `${user.dep_amount} TRX` : <Shimmer data={{ w: 120, h: 20 }} />}
                           </span>
                        </div>
                     </div>
                     <div className="cash__item cash-item cash-item_dividend">
                        <div className="cash-item__col">
                           <div className="cash-item__title">
                              Payouts
                           </div>
                           <span className="cash-item__amount">
                              {user ? `${user.pay_count}` : <Shimmer data={{ w: 80, h: 20 }} />}
                           </span>
                        </div>
                        <div className="cash-item__col">
                           <div className="cash-item__title">
                              Total Payouts
                           </div>
                           <span className="cash-item__amount">
                              {user ? `${user.pay_amount} TRX` : <Shimmer data={{ w: 120, h: 20 }} />}
                           </span>
                        </div>
                     </div>
                     <div className="cash__item cash-item cash-item_referral">
                        <div className="cash-item__col">
                           <div className="cash-item__title">
                              Partners
                           </div>
                           <span className="cash-item__amount">
                              {user ? `${user.ref_count}` : <Shimmer data={{ w: 80, h: 20 }} />}
                           </span>
                        </div>
                        <div className="cash-item__col">
                           <div className="cash-item__title">
                              Partners Rewards
                           </div>
                           <span className="cash-item__amount">
                              {user ? `${user.ref_amount} TRX` : <Shimmer data={{ w: 120, h: 20 }} />}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="ref-link">
            <div className="container">
               <div className="ref-link__inner">
                  <div className="ref-link__title">
                     Your affiliate link
                  </div>
                  {
                     user ? <div className="ref-link__link" style={{ cursor: `copy` }} rel="noreferrer"
                        onClick={() => CopyToClipboard(`${window.location.origin}/${user?.refcode}`)}>{window.location.origin}/{user?.refcode}</div>
                        :
                        <Shimmer data={{ w: 200, h: 20 }} />
                  }
               </div>
            </div>
         </div>
      </>
   );
};

export default Cabinet;