import React, { useContext, useEffect } from 'react';
import { MyContext } from '../../App';
import { CheckIfWalletOrLogout, CopyToClipboard, Time, convertDate } from '../../utils/Utils';
import axios from 'axios';
import { Configs } from '../../config';
import { IPartnersResponse } from '../../interfaces/ServerResponse';
import Shimmer from '../../utils/Shimmer';

const Partners = () => {

   const { partners, partnersLast, setPartnersLast, setPartners, currentTab, setCurrentTab, user, UpdateUserData } = useContext(MyContext);

   useEffect(() => {
      if (currentTab !== "Partners") setCurrentTab("Partners");
      const wallet = CheckIfWalletOrLogout();
      if (!wallet) return;
      if (!user) UpdateUserData();
      let ourReq = axios.CancelToken.source();
      if (partnersLast < Time()) {
         setPartners(null);
         (async () => {
            try {
               const data = (await axios.get(`${Configs.API_URL}/partners`, { headers: { jwt: wallet }, cancelToken: ourReq.token })).data as IPartnersResponse;
               if (!data.data) return;
               setPartners(data.data);
               setPartnersLast(Time() + 5);
            }
            catch (e) {
               return;
            }
         })();
      }
      return () => ourReq.cancel();
   }, [partnersLast, setPartners, setPartnersLast, currentTab, setCurrentTab, UpdateUserData, user]);

   return (
      <div className="profile-stat" style={{ marginTop: `30px` }}>
         <div className="profile-info">
            <div className="container">
               <div className="profile-info__inner">
                  <div className="offer">
                     <div className="offer__inner">
                        <input className="button button_dark offer__button"
                           id="callback-address"
                           readOnly
                           type="text"
                           style={{ cursor: `copy`, zIndex: 1, width: '100%' }}
                           value={`${window.location.origin}/${user?.refcode}`}
                           onClick={CopyToClipboard}
                        />
                     </div>
                  </div>
                  <div className="cash">
                     <div className="cash__item cash-item cash-item_referral">
                        <div className="cash-item__col">
                           <div className="cash-item__title">
                              Total Partners
                           </div>
                           <span className="cash-item__amount">
                              {partners ? `${partners.total_partners}` : <Shimmer data={{ w: 80, h: 20 }} />}
                           </span>
                        </div>
                     </div>
                     <div className="cash__item cash-item cash-item_dividend margin-top-100">
                        <div className="cash-item__col">
                           <div className="cash-item__title">
                              Total Bonus
                           </div>
                           <span className="cash-item__amount">
                              {partners ? `${partners.total_bonus} TRX` : <Shimmer data={{ w: 120, h: 20 }} />}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="container" style={{ marginTop: '50px' }}>
            <h2 style={{ textAlign: `center` }}>Your Partners</h2>
            <div className="profile-stat__inner">
               <div className="profile-stat-table-wrap">
                  <table className="profile-stat-table">
                     <tbody>
                        <tr>
                           <th>Date</th>
                           <th>Wallet</th>
                           <th>Invest</th>
                           <th>Bonus</th>
                        </tr>
                        {
                           partners ? (
                              partners.partners.length ? (
                                 partners.partners.map((partner, i) => (
                                    <tr key={i}>
                                       <td>{convertDate(partner.joined)}</td>
                                       <td>{(partner.wallet.slice(0, 20))}***</td>
                                       <td>{partner.invest.toFixed(2)} TRX</td>
                                       <td>{partner.bonus.toFixed(2)} TRX</td>
                                    </tr>
                                 ))
                              ) : (
                                 <tr>
                                    <td colSpan={5} style={{ textAlign: `center` }}>You have no partners</td>
                                 </tr>
                              )
                           ) : (
                              <>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                                 <tr>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                    <td><Shimmer data={{ w: '100%', h: 20 }} /></td>
                                 </tr>
                              </>
                           )
                        }
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Partners;